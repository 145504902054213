* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
}

.hero-text h1 {
  color: #c3a510;
  font-size: 40px;
}

.hero-text h3 {
  color: white;
  font-size: 24px;
}

h2 {
  font-size: 32px;
}

h3 {
  color: black;
}

.about-text h3 {
  font-size: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav ul {
  list-style-type: none;
}

a.btn__cta {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4ce14;
  border-radius: 8px;
  padding: 8px 16px;
}

img.img__hero {
  border-radius: 16px;
}

img.img__menu {
  aspect-ratio: 1.6/1;
}

.card__menu h3 {
  font-weight: 600;
}

footer {
  background-color: #eeeeee;
}

footer h3 {
  font-weight: 600;
}
